import React, { useState } from 'react';

import Input from '../../../components/Input';

interface DisplayNotificationContentProps {
  metadata: {
    [propertyName: string]: any;
  };
  onChange: (propertyName: string, value: any) => void;
}

const DisplayNotificationContent: React.FC<DisplayNotificationContentProps> = ({
  metadata,
  onChange,
}) => {
  // *intitalize the original meta data
  const [notiMetaData, setnotiMetaData] = useState<{
    [propertyName: string]: any;
  }>({
    alertDuration: metadata?.alertDuration,
    alertAmount: metadata?.alertAmount,
  });

  return (
    <>
      <div>
        <label>Number of notification</label>
        <Input
          sx={{
            width: '100%',
            display: 'block',
            placeholderShown: 'true',
            '& > input': { backgroundColor: '#fff' },
          }}
          value={notiMetaData?.alertAmount || ''}
          placeholder={'Default : 10 (1-10)'}
          onChange={(e) => {
            setnotiMetaData({
              ...notiMetaData,
              alertAmount: Number(e.currentTarget.value),
            });
            onChange('alertAmount', e.currentTarget.value);
          }}
        ></Input>
        <label>Auto hide duration (ms)</label>
        <Input
          sx={{
            width: '100%',
            display: 'block',
            placeholderShown: 'true',
            '& > input': { backgroundColor: '#fff' },
          }}
          value={notiMetaData?.alertDuration || ''}
          placeholder={'Default : 20000'}
          onChange={(e) => {
            setnotiMetaData({
              ...notiMetaData,
              alertDuration: Number(e.target.value),
            });
            onChange('alertDuration', e.target.value);
          }}
        ></Input>
      </div>
    </>
  );
};

export default DisplayNotificationContent;

import { styled } from '@mui/material';

const RmrButton = styled('button')(({ theme }) => {
  return {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    fontSize: '0.875rem',
    color: theme.color.secondary.$80,
    fontWeight: '500',
    padding: '16px',
    border: '1px solid #E5E5E5',
    borderRadius: '8px',
    backgroundColor: theme.color.gray.$250,

    '&:hover': {
      color: theme.color.primary.$100,
      backgroundColor: '#FFF',
      '&:disabled': {
        backgroundColor: theme.color.gray.$250,
      },
    },

    '&.cause': {
      backgroundColor: theme.color.gray.$240,
      '&:hover': {
        backgroundColor: '#F5F5F5',
        '&:disabled': {
          backgroundColor: theme.color.gray.$250,
        },
      },
    },

    '&:disabled': {
      cursor: 'default',
      opacity: '0.3',
    },
  };
});

export default RmrButton;

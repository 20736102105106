import { CustomLayerInterface, Map, MercatorCoordinate } from 'mapbox-gl';
import {
  AmbientLight,
  Box3,
  Camera,
  DirectionalLight,
  Matrix4,
  Scene,
  Vector3,
  WebGLRenderer,
} from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';

import { Model } from '../types';

import { MeshoptDecoder } from './3d/meshopt_decoder.module';
import { CoordinateConverter } from './CoordinateConverter';

export const getModelTransform = (
  lngLatAlt: [number, number, number],
  rotate: [number, number, number],
) => {
  const mc = MercatorCoordinate.fromLngLat(
    [lngLatAlt[0], lngLatAlt[1]],
    lngLatAlt[2],
  );

  return {
    translateX: mc.x,
    translateY: mc.y,
    translateZ: mc.z,
    rotateX: rotate[0],
    rotateY: rotate[1],
    rotateZ: rotate[2],
    scale: mc.meterInMercatorCoordinateUnits(),
  };
};

export const create3DModelLayer = (
  center: [number, number],
  projectElevation: number,
  models: Model[],
): { layer: CustomLayerInterface; scene: Scene } => {
  const camera: Camera = new Camera();
  const scene: Scene = new Scene();
  let renderer: WebGLRenderer;
  let map: Map;

  return {
    layer: {
      id: '3d-model-layer',
      type: 'custom',
      renderingMode: '3d',
      onAdd: (m, gl) => {
        const converter = new CoordinateConverter(center[1], center[0]);

        const directionalLight2 = new DirectionalLight(0xffffff);
        directionalLight2.position.set(5, 70, 5).normalize();
        scene.add(directionalLight2);

        const light = new AmbientLight(0x404040);
        scene.add(light);

        const loader = new GLTFLoader();
        loader.setMeshoptDecoder(MeshoptDecoder);
        models.forEach((model) => {
          let isTaller = true;

          if (
            typeof model.coordinates?.alt !== 'undefined' &&
            model.coordinates.alt < projectElevation
          ) {
            isTaller = false;
          }

          loader.load(model.gltfURL, (gltf) => {
            gltf.scene.rotation.set(
              ((model.rotation?.roll ?? 0) * Math.PI) / 180,
              ((model.rotation?.heading ?? 0) * Math.PI) / 180,
              ((model.rotation?.pitch ?? 0) * Math.PI) / 180,
            );
            const scaleValue = model.scale ?? 1;
            gltf.scene.scale.set(scaleValue, scaleValue, scaleValue);
            gltf.scene.updateMatrixWorld();
            const aabb = new Box3().setFromObject(gltf.scene);
            const aabbCenter = aabb.getCenter(new Vector3());
            const position = converter.geographicToCartesian(
              model.coordinates?.lat ?? 0,
              model.coordinates?.lon ?? 0,
            );
            gltf.scene.userData.name = model.name;
            gltf.scene.position.set(
              position.x * -1 - aabbCenter.x,
              isTaller
                ? 0 + (model.heightTune ?? 0)
                : (model.coordinates?.alt ?? 0) -
                    projectElevation +
                    (model.heightTune ?? 0),
              position.z * -1 - aabbCenter.z,
            );

            scene.add(gltf.scene);
          });
        });

        map = m;

        renderer = new WebGLRenderer({
          canvas: map.getCanvas(),
          context: gl,
          antialias: true,
        });

        renderer.autoClear = false;
      },
      render: (gl, matrix) => {
        const mt = getModelTransform(
          [center[0], center[1], 0],
          [Math.PI / 2, 0, 0],
        );

        const rotationX = new Matrix4().makeRotationAxis(
          new Vector3(1, 0, 0),
          mt.rotateX,
        );
        const rotationY = new Matrix4().makeRotationAxis(
          new Vector3(0, 1, 0),
          mt.rotateY,
        );
        const rotationZ = new Matrix4().makeRotationAxis(
          new Vector3(0, 0, 1),
          mt.rotateZ,
        );

        const m = new Matrix4().fromArray(matrix);
        const l = new Matrix4()
          .makeTranslation(mt.translateX, mt.translateY, mt?.translateZ ?? 0)
          .scale(new Vector3(mt.scale, -mt.scale, mt.scale))
          .multiply(rotationX)
          .multiply(rotationY)
          .multiply(rotationZ);

        camera.projectionMatrix = m.multiply(l);
        renderer.resetState();
        renderer.render(scene, camera);
        map.triggerRepaint();
      },
      onRemove: () => {
        scene.remove();
        camera.remove();
        renderer.clear();
      },
    },
    scene,
  };
};

import { useState } from 'react';
import { styled } from '@mui/material';

import Input from '../../../components/Input';

import { inputSx } from '../../../theme';

const List = styled('ul')`
  padding: 0;
  & > li {
    display: flex;
    gap: 10px;
    align-items: center;
    list-style-type: none;
  }

  & > .text-field {
    margin-top: 10px;
  }
`;

interface CapOffDialogContentProps {
  metadata: {
    [propertyName: string]: any;
  };
  onChange: (propertyName: string, value: any) => void;
}

const CapOffDialogContent: React.FC<CapOffDialogContentProps> = ({
  metadata,
  onChange,
}) => {
  const [capOffMetadata, setCapOffMetadata] = useState<{
    [propertyName: string]: any;
  }>({
    maxAttempts: metadata?.maxAttempts,
    maxPeriod: metadata?.maxPeriod,
  });

  return (
    <List>
      <div className="text-field">
        <label>Max Period (mins)</label>
        <Input
          sx={{ ...inputSx, '& > input': { backgroundColor: '#fff' } }}
          value={capOffMetadata?.maxPeriod || ''}
          placeholder="Default: 5"
          onChange={(e) => {
            setCapOffMetadata({
              ...capOffMetadata,
              maxPeriod: Number(e.currentTarget.value),
            });
            onChange('maxPeriod', e.currentTarget.value);
          }}
        />
      </div>
      <div className="text-field">
        <label>Max number of alerts</label>
        <Input
          sx={{
            ...inputSx,
            '& > input': { backgroundColor: '#fff' },
          }}
          value={capOffMetadata?.maxAttempts || ''}
          placeholder="Default: 5"
          onChange={(e) => {
            setCapOffMetadata({
              ...capOffMetadata,
              maxAttempts: Number(e.currentTarget.value),
            });
            onChange('maxAttempts', e.currentTarget.value);
          }}
        />
      </div>
    </List>
  );
};

export default CapOffDialogContent;
